import { HomePage } from '@b7hio/ops-ui-lib/src/features/home/components';
import { GetStaticProps } from 'next';
import { ssrTranslations } from '../hooks/ssr-translations';

export default function HomeRoute() {
  return <HomePage portal="ops" />;
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  const tProps = await ssrTranslations(ctx, ['shell', 'common', 'home']);

  return {
    props: {
      ...tProps,
    },
  };
};
